$(function () {
    function showSubPage($target) {
        $target.slideDown(400);
    }

    var $links = $('.subMenuLinks li a, .mainMenu li a');
    var $bodyHtml = $('html, body');
    var $headerHeight = $('.header').height();
    $links.on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $targetPath = $this.attr('href');
        var $target = $($targetPath);
        var $targetScrollTo = $target.offset().top;
        if ($target.length > 0) {
            $links.parents('li').removeClass('active');
            $this.parents('li').addClass('active');
            var $parentWrapper = $target.closest('.subPages');
            var $slideTarget = $($target, $parentWrapper);
            if($slideTarget.length > 0 && $parentWrapper.length > 0) {
                var $className = $targetPath.substring(1);
                var $subMenuLink = $('.subMenuLinks a.page-'+ $className);
                if($subMenuLink.length > 0) {
                    $subMenuLink.parent('li').addClass('active');
                }
                if (!$target.is(':visible')) {
                    if ($('.subpageContent:visible').length > 0) {
                        $('.subpageContent:visible', $parentWrapper).slideUp(400, function() {
                            showSubPage($($targetPath, $parentWrapper));
                        });
                    } else {
                        showSubPage($($targetPath, $parentWrapper));
                    }
                }
                $targetScrollTo = $target.parent().offset().top;
            }
            $bodyHtml.animate({ scrollTop: ($targetScrollTo - $headerHeight) }, 1000);
        }
    });

    $('.logo').on('click', function(e) {
        e.preventDefault();
        $bodyHtml.animate({ scrollTop: 0 }, 1000);
    });

    $('.showAllGallery').on('click', function() {
        var $this = $(this);
        var oldText = $this.text();
        var newText = $this.data('toggle-text');
        $this.text(newText).data('toggle-text', oldText);
        $this.prev('.gallery').toggleClass('full');
    });

    $('.toggle').readmore(
        {
            moreLink: '<a href="#">'+ LANGUAGE_STRING['READ_MORE'] + '</a>',
            lessLink: '<a href="#">' + LANGUAGE_STRING['CLOSE'] + '</a>',
            maxHeight : 215,
        }
    );

    $contactForm = $("#contact_form form");

    $contactForm.submit(function( event ) {
        event.preventDefault();
        var alertMsg = '';

        $contactForm.find('input, textarea').each(function(){
            var text = $(this).val();
            if ($(this).val().search($(this).data('original')) >= 0) {
                if (alertMsg == '') {
                    alertMsg += LANGUAGE_STRING['ERROR_AJAX_CONTACT_FORM']+':\n';
                }
                alertMsg += $(this).data('original')+'\n';
            }
        });
        if (alertMsg != '') {
            alert(alertMsg);
            return true;
        }
        
        $.post( BASE_URL+'ajax/contact.php', $contactForm.serialize(), function(response) {
        var data = $.parseJSON(response);
        if(data.status == 'OK'){
            alert(LANGUAGE_STRING['MESSAGE_SENT']);
            return true;
        }
        $.each(data.errors, function(key, value){
            alertMsg += value+'\n';
        })
        alert(alertMsg)
      });
    });

    if(RESPONSIVE) {
        var $slideBars      = new $.slidebars(); // Create new instance of Slidebars
        var $slidebarLeft   = $('.slidebarLeft');
        var $slidebarRight  = $('.slidebarRight');
        var $sbLeft         = $('.sb-left');
        var $sbRight        = $('.sb-right');

        //Open left slidebar
        if($sbLeft.length > 0) {
            $slidebarLeft.on('click', function() {
                $slideBars.slidebars.toggle('left');
            });
        }

        //Open right slidebar
        if($sbRight.length > 0) {
            $slidebarRight.on('click', function() {
                $slideBars.slidebars.toggle('right');
            });

            $sbRight.on('click', '.closeSidebar', function(e) {
                e.preventDefault();
                $slideBars.slidebars.close();
            });

            $sbRight.on('openCart', function() {
                $slideBars.slidebars.open('right');
            });
        }

        //Initialize fastclick to avoid the click delay on touchscreens.
        if($(window).width <= 1024) {
            FastClick.attach(document.body);
        }
    }
});
